<template>
    <ion-content :fullscreen="true">
      <ion-grid v-if="isErrorScreen">
        <ion-row class="ion-margin">
          <ion-col>
           <img width="320" height="200" src="https://cdn.gifted-pocket.com/images/callCentre.png" />
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin">
          <ion-col> 
           <ion-card>
              <ion-card-header>
                <ion-card-title color="danger">{{msg}}</ion-card-title>
              </ion-card-header>
              <ion-card-content class="ion-margin ion-justify-content-center">
                <h2>QRコードからログインしてください</h2>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid  v-else>
         <ion-row class="ion-margin ion-justify-content-center">
           <img class="spacer" width="320" height="200" src="https://cdn.gifted-pocket.com/images/family.png" />
        </ion-row>
        <ion-row class="ion-margin ion-justify-content-center">
          <h1>SYABERU</h1>
        </ion-row>
        <ion-row class="ion-margin ion-justify-content-center">
          <ion-item lines="none">
              <input id="email" type="email" v-model="email" class="email" placeholder="E-mail"/>
              <ion-icon :icon="mailOutline" slot="start"/>
          </ion-item>
        </ion-row>
        <ion-row class="ion-margin ion-justify-content-center" v-if="hasErrorMsg">
          <strong><ion-label color="danger" slot="end">{{msg}}</ion-label></strong>
        </ion-row>
        <ion-row class="ion-margin ion-justify-content-center">
          <ion-button shape="round" @click="signIn">ログイン</ion-button>
        </ion-row>
      </ion-grid>
    </ion-content>
</template>

<script>
import { mailOutline } from 'ionicons/icons';
import {
  IonContent,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel
} from '@ionic/vue';
export default  {
  name: 'Login',
  components : {
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel
  },
  data(){
    return {
      email: '',
      password: '',
      tenantId: null,
      msg: '',
      isOpenRef : false,
      isErrorScreen : false,
      hasErrorMsg : false
    }
  },
  setup() {
    return {
      mailOutline
    }
  },
  mounted(){
      //そもそもGetのパラメータが存在しないなら、描画を中止する。 
      if(this.$route.query.tenantId){
        this.tenantId = this.$route.query.tenantId;
        document.getElementById('email').focus();
        this.isErrorScreen = false;
      }else{
        this.isErrorScreen = true;
      }
  },
  methods : {
    isNotEmpty(value){
      return value !== null && value !== '';
    },
    isValidEmail(email){
      //email
      const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      return regexp.test(email);
    },
    validate(email,password,tenantId){
      let result = this.isNotEmpty(email);
      result = result & this.isNotEmpty(tenantId);
      result = result & this.isValidEmail(email);
      return result;
    },
    /**
     * ログインメソッド。TSUMUGUのDBにある保護者情報から児童の情報を取得する。
     * tenantId込みのURLから遷移してくる想定で。TSUMUGUのGreeting.vueのQRコードと呼応する。
     */
    async signIn(){
      // validatorを利用する必要あり
      if(this.validate(this.email,
                       this.tenantId)){
        this.$root.startLoading();
        const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
        const USERS = FUNCTIONS_HOST_NAME + "/users/findByGuardianEmail"      
        let params = {
          'tenantId' : this.tenantId,
          'email' : this.email
        }
        let response = await this.axios.post(USERS,params);
        const users = response.data;
        console.log(users);
        if(users.length == 0){
          this.hasErrorMsg = true;
          this.msg = '入力が不適切です';
          this.email = '';
          document.getElementById('email').focus();
        }else{
          let destination = '';
          if(users){
            if(users.length == 0){
              this.msg = 'データが取得できませんでした。しばらくしてからやりなおしてください';
            }else if(users.length == 1){
              // only 1 child at this time, then do not need to choose which user to show
              this.$store.commit('setActiveUser',users[0]);
              destination = 'home';
            }else{
              // this storage is for user choice
              this.$store.commit('setUsers',users);
              destination = 'selectUser';
            }
          }
          this.$router.push(destination);
        }
        this.$root.endLoading();
      }else{
        //ERROR
        this.hasErrorMsg = true;
        this.msg = '入力が不適切です';
        this.email = '';
        document.getElementById('email').focus();
      }
    }
  }
}
</script>
<style>
.email{
  padding: 10px 15px;
  border-radius: 3px;
  width: 100%;
  border: 2px solid #ddd; /*枠線*/
}
.spacer{
  margin-top: 75px;
}
</style>